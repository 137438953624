// eslint-disable-next-line @nx/enforce-module-boundaries
import packageJson from '../../../../package.json';

export const environment = {
  useEmulators: false,
  production: true,
  appVersion: packageJson.version,
  firebase: {
    backOffice: {
      apiKey: 'AIzaSyB5slIFHWffvGcVYDo0tH4DzvIvJyx130Y',
      authDomain: 'ecolepapilloninvisible.firebaseapp.com',
      projectId: 'ecolepapilloninvisible',
      storageBucket: 'ecolepapilloninvisible.appspot.com',
      messagingSenderId: '975637074887',
      appId: '1:975637074887:web:bd4751a1f4badf96d6ffe2',
      measurementId: 'G-R1RMYQ6EHD',
    },
  },
  mapBox: {
    accesstoken: 'pk.eyJ1IjoidGVnYWluIiwiYSI6ImNseXZwcmgzNTEzNHgyaXFzNzgzc2s1bm0ifQ.CHYncZYuQPeYriaMFqTLWA',
    searchBaseUrl: 'https://api.mapbox.com/search/searchbox/v1/suggest',
  },
  sentry: {
    dsn: 'https://92172420cc2a5a0575eef1f4740314e3@o4507641167478784.ingest.de.sentry.io/4507641170821200',
  },
  siteUrl: 'https://www.ecoledupapilloninvisible.fr',
};
